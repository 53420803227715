import React, {useState} from 'react';
import axiosApi from "../../utils/api";
import {TriangleAlert} from "lucide-react";
import {useNavigate} from "react-router-dom";

const socialMediaOptions = ["twitter", "telegram", "reddit", "github"];

const EditProjectSection = ({projectId, refreshProjects, ignoreProject}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [fetchError, setFetchError] = useState(null);
    const [editingProject, setEditingProject] = useState(null);
    const [newSocial, setNewSocial] = useState({site: "", link: "", username: ""});
    const navigate = useNavigate();
    const [contractPreview, setContractPreview] = useState(null);

    const fetchProject = React.useCallback(() => {
        setFetchError(null);
        setIsFetching(true);
        axiosApi.get(`/projects/${projectId}`)
            .then(response => {
                setEditingProject(response.data);
                setIsFetching(false);
            })
            .catch(err => {
                console.error(err);
                setIsFetching(false);
                setFetchError(err);
            });
    }, [projectId]);

    React.useEffect(() => {
        fetchProject();
    }, [projectId, fetchProject]);


    const handleSave = () => {
        setIsSaving(true);
        axiosApi.post(`/projects/${editingProject.id}/update-details`, editingProject)
            .then((resp) => {
                setIsSaving(false);
                setEditingProject(resp.data);
                refreshProjects();
            })
            .catch(err => {
                console.error(err);
                setIsSaving(false);
            });
    };


    const setIgnoreProject = (isIgnored) => {
        setIsSaving(true);
        axiosApi.post(`/projects/${editingProject.id}/update-ignored/${isIgnored}`)
            .then((resp) => {
                setIsSaving(false);
                setEditingProject(resp.data);
                ignoreProject(resp.data.id, isIgnored);
            })
            .catch(err => {
                console.error(err);
                setIsSaving(false);
            });
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setEditingProject(prev => ({...prev, [name]: value}));
    };

    const handleSocialInputChange = (e) => {
        const {name, value} = e.target;
        setNewSocial(prev => ({...prev, [name]: value}));
    };

    const handleAddSocial = () => {
        if (newSocial.site && newSocial.link && newSocial.username) {
            setEditingProject(prev => ({
                ...prev,
                socials: {
                    ...prev.socials || {},
                    [newSocial.site]: {...newSocial}
                }
            }));
            setNewSocial({site: "", link: "", username: ""});
        }
    };

    const handleRemoveSocial = (site) => {
        setEditingProject(prev => {
            const updatedSocials = {...prev.socials || {}};
            delete updatedSocials[site];
            return {...prev, socials: updatedSocials};
        });
    };

    const goBack = () => {
        navigate('/admin');
    }

    if (isFetching) {
        return (
            <div className="bg-black bg-opacity-50 flex items-center justify-center basis-2/3">
                <div className="flex justify-center items-center h-64">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
                </div>
            </div>
        );
    }

    if (fetchError) {
        return (
            <div className="bg-black bg-opacity-50 flex items-center justify-center basis-2/3">
                <div className="rounded-lg flex flex-col">
                    <TriangleAlert size={48} className="text-red-500 mx-auto mb-4"/>
                    <p className="text-white">An error occurred while fetching project details.</p>
                </div>
            </div>
        );
    }

    const previewContract = editingProject?.contracts?.find(contract => contract.address === contractPreview);

    editingProject.contractAddresses = [...new Set(editingProject?.contractAddresses || [])];


    const iframeWidth = window.innerWidth * 0.75;
    const iframeHeight = window.innerHeight * 0.75;

    return (
        <div className="bg-black bg-opacity-50 flex flex-wrap flex-row overflow-y-auto custom-scrollbar px-4 basis-2/3 content-start">
            {contractPreview !== null && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-gray-800 rounded-lg max-h-screen overflow-y-auto">
                        {/*<CodeViewer rawCode={previewContract?.sourceCode} setContractPreview={setContractPreview}/>*/}
                        <iframe src={`https://vscode.blockscan.com/ethereum/${previewContract?.address}`}
                                width={iframeWidth} height={iframeHeight} title="Contract Viewer"/>
                    </div>
                    <div className="fixed inset-0 -z-10" onClick={() => setContractPreview(null)}/>
                </div>
            )}
            <div className="w-full h-min pb-4">
                <h2 className="text-lg font-bold mb-4">Contract Addresses:</h2>
                <ul className="list-inside list-disc ml-1">
                    {editingProject?.contracts?.map((contract, index) => (
                        <li key={index} className="text-sm mb-1">
                            {contract.contractName} - {contract.address}
                            <div className="ml-4">
                                            <span onClick={() => setContractPreview(contract.address)} x
                                                  rel="noreferrer"
                                                  className="text-blue-400 hover:text-blue-300 mr-2 cursor-pointer">View Code</span>
                                <a href={`https://etherscan.io/address/${contract.address}`}
                                   target="_blank"
                                   rel="noreferrer"
                                   className="text-blue-400 hover:text-blue-300">Etherscan</a>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="w-full flex-grow max-w-4xl py-4 overflow-y-auto border-y border-purple-500 border-dashed mb-auto">
                <div className="flex flex-col px-0.5 md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                    <div className="md:w-2/3 space-y-4">
                        <div>
                            <label htmlFor="projectName" className="block text-sm font-medium">Project Name</label>
                            <input id="projectName" name="projectName" value={editingProject.projectName}
                                   onChange={handleInputChange} className="w-full p-2 bg-gray-700 rounded"/>
                        </div>
                        <div>
                            <label htmlFor="website" className="block text-sm font-medium">Website</label>
                            <input id="website" name="website" value={editingProject.website}
                                   onChange={handleInputChange} className="w-full p-2 bg-gray-700 rounded"/>
                        </div>
                        <div>
                            <label htmlFor="description" className="block text-sm font-medium">Description</label>
                            <textarea id="description" name="description" value={editingProject.description || ''}
                                      onChange={handleInputChange} className="w-full p-2 bg-gray-700 rounded h-24"/>
                        </div>
                        <div>
                            <label htmlFor="developerName" className="block text-sm font-medium">Developer Name</label>
                            <input id="developerName" name="developerName" value={editingProject.developerName}
                                   onChange={handleInputChange} className="w-full p-2 bg-gray-700 rounded"/>
                        </div>
                        <div className="flex items-center space-x-4">
                            <div className="flex items-center">
                                <input id="isPremium" name="isPremium" type="checkbox"
                                       checked={editingProject.isPremium} onChange={(e) => handleInputChange({
                                    target: {
                                        name: 'isPremium',
                                        value: e.target.checked
                                    }
                                })} className="mr-2"/>
                                <label htmlFor="isPremium" className="text-sm font-medium">Premium Project</label>
                            </div>
                            <div className="flex items-center">
                                <input id="isValid" name="isValid" type="checkbox" checked={editingProject.isValid}
                                       onChange={(e) => handleInputChange({
                                           target: {
                                               name: 'isValid',
                                               value: e.target.checked
                                           }
                                       })} className="mr-2"/>
                                <label htmlFor="isValid" className="text-sm font-medium">Publish Project</label>
                            </div>
                        </div>
                        <div>
                            <label htmlFor="logo" className="block text-sm font-medium">Logo URL</label>
                            <input id="logo" name="logo" value={editingProject.logo || ''} onChange={handleInputChange}
                                   className="w-full p-2 bg-gray-700 rounded mb-2"/>
                            {editingProject.logo && <img src={editingProject.logo} alt="Logo preview"
                                                         className="w-16 h-16 object-contain"/>}
                        </div>
                    </div>
                    <div className="md:w-1/3 pl-4">
                        <h3 className="text-lg font-semibold">Social Media</h3>
                        {Object.entries(editingProject.socials || {}).map(([site, social]) => (
                            <div key={site} className="flex items-center justify-between py-2">
                                <span>{site}: {social.username}</span>
                                <button onClick={() => handleRemoveSocial(site)}
                                        className="bg-red-500 hover:bg-red-600 text-white px-2 py-1 rounded text-xs">Remove
                                </button>
                            </div>
                        ))}
                        <div className="space-y-2">
                            <select name="site" value={newSocial.site} onChange={handleSocialInputChange}
                                    className="w-full p-2 bg-gray-700 rounded">
                                <option value="">Select Social Media</option>
                                {socialMediaOptions.map(option => <option key={option}
                                                                          value={option}>{option}</option>)}
                            </select>
                            <input type="text" name="link" value={newSocial.link} onChange={handleSocialInputChange}
                                   placeholder="Link" className="w-full p-2 bg-gray-700 rounded"/>
                            <input type="text" name="username" value={newSocial.username}
                                   onChange={handleSocialInputChange} placeholder="Username"
                                   className="w-full p-2 bg-gray-700 rounded"/>
                            <button onClick={handleAddSocial}
                                    className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded w-full">Add
                                Social Media
                            </button>
                        </div>
                    </div>
                </div>
                <div className="mt-4 flex justify-end space-x-2">
                    {!editingProject.isIgnored ? (
                        <button
                            onClick={() => setIgnoreProject(true)}
                            disabled={isSaving}
                            className="text-red-500 border border-transparent hover:border-red-500 transition-all px-4 py-2 rounded mr-4">
                            Ignore Project
                        </button>
                    ) : (
                        <button
                            onClick={() => setIgnoreProject(false)}
                            disabled={isSaving}
                            className="text-green-500 border border-transparent hover:border-green-500 transition-all px-4 py-2 rounded mr-4">
                            Unignore Project
                        </button>
                    )}

                    <button onClick={handleSave} disabled={isSaving}
                            className="bg-purple-700 hover:bg-purple-500 text-white px-4 py-2 rounded disabled:opacity-50">
                        {isSaving ? 'Saving...' : 'Save Changes'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditProjectSection;