import React, {useState, useRef, useEffect} from 'react';
import {BadgeCheck, ChevronDown, ChevronUp, FilePlus2, SquareSlash} from 'lucide-react';
import _ from 'lodash';
import axiosApi from "../../utils/api";

const VerticalDateSlider = ({filters, setFilters}) => {
    // Convert initial date to UTC
    const today = new Date(filters.first_deploy__gte);
    const todayUTC = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));

    const [selectedDate, setSelectedDate] = useState(todayUTC);
    const [showMonthPicker, setShowMonthPicker] = useState(false);
    const [dateRange, setDateRange] = useState({
        start: -365,  // Days before today
        end: 2        // Today (no future dates)
    });

    const sliderRef = useRef(null);
    const [dailyStats, setDailyStats] = useState({});

    // Generate dates based on the current range using UTC
    const dates = React.useMemo(() => {
        return Array.from(
            {length: dateRange.end - dateRange.start},
            (_, i) => {
                const date = new Date(todayUTC);
                date.setUTCDate(date.getUTCDate() + (dateRange.start + i));
                return date;
            }
        );
    }, [dateRange]);

    // Fetch daily stats for each date using UTC dates
    useEffect(() => {
        const startDate = dates[0].toISOString().split('T')[0];
        axiosApi.get('/stats/daily', {
            params: {
                start_date: startDate,
                min_score: filters.min_score,
            }
        }).then((response) => {
            setDailyStats(response.data);
        })
    }, [dates]);

    // Generate unique months for the month picker using UTC
    const months = React.useMemo(() =>
            Array.from(new Set(dates.map(date => {
                const monthName = new Intl.DateTimeFormat('en-US', {
                    month: 'long',
                    timeZone: 'UTC'
                }).format(date);
                return `${monthName} ${date.getUTCFullYear()}`;
            }))),
        [dates]
    );

    const handleDateClick = (date) => {
        setSelectedDate(date);
        // Create UTC start and end of day
        const startOfDay = new Date(Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            0, 0, 0, 0
        ));
        const endOfDay = new Date(Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            23, 59, 59, 999
        ));

        setFilters(prev => ({
            ...prev,
            first_deploy__gte: startOfDay.toISOString(),
            first_deploy__lte: endOfDay.toISOString()
        }));
    };

    const scrollToDate = (date) => {
        const targetElement = document.getElementById(`date-${date.toISOString()}`);
        if (targetElement && sliderRef.current) {
            targetElement.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    };

    const scrollToMonth = (monthYear) => {
        const [month, year] = monthYear.split(' ');
        const targetDate = dates.find(date => {
            const monthName = new Intl.DateTimeFormat('en-US', {
                month: 'long',
                timeZone: 'UTC'
            }).format(date);
            return monthName === month && date.getUTCFullYear().toString() === year;
        });

        if (targetDate) {
            scrollToDate(targetDate);
        }
        setShowMonthPicker(false);
    };

    // Group dates by UTC month
    const groupedDates = _.groupBy(dates, date =>
        `${date.getUTCMonth()}-${date.getUTCFullYear()}`
    );

    const formatDateToDisplay = (date) => {
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC'
        }).format(date);
    };

    return (
        <div className="flex flex-col h-full mr-4 rounded-lg w-max">
            {/* Selected Date Display */}
            <div className="p-2 border-b border-gray-700">
                <button
                    onClick={() => scrollToDate(selectedDate)}
                    className="w-full px-4 py-2 text-purple-400 rounded-md text-center transition-colors"
                >
                    {formatDateToDisplay(selectedDate)}
                </button>
            </div>

            {/* Month Quick Jump */}
            <div className="relative p-2 border-b border-gray-700">
                <button
                    onClick={() => setShowMonthPicker(!showMonthPicker)}
                    className="w-full px-4 py-2 text-left rounded-md flex justify-between items-center"
                >
                    <span>Jump to Month</span>
                    {showMonthPicker ? <ChevronUp size={20}/> : <ChevronDown size={20}/>}
                </button>

                {showMonthPicker && (
                    <div className="absolute top-full left-0 right-0 z-50 mt-1 border border-purple-500 bg-black rounded-md shadow-lg custom-scrollbar max-h-48 overflow-y-auto">
                        {months.map((month) => (
                            <button
                                key={month}
                                onClick={() => scrollToMonth(month)}
                                className="w-full px-4 py-2 text-left hover:bg-gray-600 text-sm"
                            >
                                {month}
                            </button>
                        ))}
                    </div>
                )}
            </div>

            {/* Date Slider */}
            <div
                ref={sliderRef}
                className="flex-1 w-max overflow-y-auto custom-scrollbar scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800"
            >
                {Object.entries(groupedDates).reverse().map(([month, monthDates]) => (
                    <div key={month} className="w-max">
                        <div className="sticky top-0 bg-gray-800 z-0 px-4 py-2 font-semibold text-purple-400 text-sm w-max">
                            {new Intl.DateTimeFormat('en-US', {
                                month: 'long',
                                year: 'numeric',
                                timeZone: 'UTC'
                            }).format(monthDates[0])}
                            <div className="text-xs text-center text-gray-400 grid grid-cols-4 space-x-1 w-max">
                                <div className="text-left">Date</div>
                                <div>New</div>
                                <div>Ignored</div>
                                <div>Published</div>
                            </div>
                        </div>
                        {monthDates.reverse().map((date) => {
                            const isSelected = date.toISOString().split('T')[0] === selectedDate.toISOString().split('T')[0];
                            const dateString = date.toISOString().split('T')[0];
                            const stats = dailyStats[dateString] || {created: 0, ignored: 0, validated: 0};

                            return (
                                <div key={date.toISOString()} id={`date-${date.toISOString()}`}>
                                    <button
                                        onClick={() => handleDateClick(date)}
                                        className={`w-full px-4 text-center py-2 grid grid-cols-4 gap-2 text-sm transition-colors ${
                                            isSelected
                                                ? 'bg-purple-700 text-white'
                                                : 'hover:bg-gray-700'
                                        }`}
                                    >
                                        <div className="text-left">
                                            <div className="font-bold">
                                                {new Intl.DateTimeFormat('en-US', {
                                                    day: '2-digit',
                                                    timeZone: 'UTC'
                                                }).format(date)}
                                            </div>
                                            <div>
                                                {new Intl.DateTimeFormat('en-US', {
                                                    month: 'short',
                                                    timeZone: 'UTC'
                                                }).format(date)}
                                            </div>
                                        </div>
                                        <div title="Projects Discovered">
                                            <span className="ml-2 text-gray-400 text-center inline-block">
                                                {stats.created || 0}<br/>
                                                <FilePlus2 size={16} className="inline"/>
                                            </span>
                                        </div>
                                        <div title="Projects Ignored">
                                            <span className="ml-2 text-gray-400 text-center inline-block">
                                                {stats.ignored || 0}<br/>
                                                <SquareSlash size={16} className="inline"/>
                                            </span>
                                        </div>
                                        <div title="Projects Published">
                                            <span className="ml-2 text-gray-400 text-center inline-block">
                                                {stats.validated || 0}<br/>
                                                <BadgeCheck size={16} className="inline"/>
                                            </span>
                                        </div>
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VerticalDateSlider;