// src/components/AdminProjectsPage.js
import React, {useState, useEffect} from 'react';
import axiosApi from "../../utils/api";
import FilterBar from './FilterBar';
import ProjectCard from './ProjectCard';
import Pagination from './Pagination';
import EditProjectSection from './EditProjectSection';
import {useLocation, useNavigate} from "react-router-dom";
import VerticalDateSlider from "./DatePickerSidebar";

const AdminProjectsPage = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const day = new Date();
    // Convert to UTC day boundary by using Date.UTC
    const utcDay = new Date(Date.UTC(
        day.getUTCFullYear(),
        day.getUTCMonth(),
        day.getUTCDate() - 1, // Previous day
        0, 0, 0, 0  // Start of day in UTC
    ));

    const now = new Date();
    const utcNow = new Date(Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        23, 59, 59, 999  // End of current day in UTC
    ));

    const [filters, setFilters] = useState({
        sortBy: 'totalScore',
        order: 'desc',
        page: 1,
        size: 10,
        min_score: 10,
        showIgnored: false,
        onlyPremium: false,
        valid: '',
        first_deploy__gte: utcDay.toISOString(),
        first_deploy__lte: utcNow.toISOString()
    });
    const [totalCount, setTotalCount] = useState(1);
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const editingProjectId = urlSearchParams.get('edit');
    const navigate = useNavigate();

    const fetchProjects = React.useCallback(() => {
        setLoading(true);
        const queryParams = new URLSearchParams({
            sortBy: filters.sortBy,
            order: filters.order,
            page: filters.page,
            size: filters.size,
            min_score: filters.min_score,
            showIgnored: filters.showIgnored,
            onlyPremium: filters.onlyPremium,
            ...(filters.first_deploy__gte !== '' && {first_deploy__gte: filters.first_deploy__gte}),
            ...(filters.first_deploy__lte !== '' && {first_deploy__lte: filters.first_deploy__lte}),
            ...(filters.valid !== '' && {valid: filters.valid})
        }).toString();

        axiosApi.get(`/projects?${queryParams}`)
            .then(response => {
                setLoading(false);
                setProjects(response.data.projects);
                if (editingProjectId && response.data.projects.length > 0) {
                    navigate(`?edit=${response.data.projects[0].id}`);
                }
                setTotalCount(response.data.total);
            })
            .catch(error => {
                console.error(error)
                setLoading(false);
                setError(error);
            });
    }, [filters]);


    const ignoreProject = (projectId, isIgnored) => {
        // silently set a project to ignored
        setProjects(prev => prev.map(project => {
            if (project.id === projectId) {
                return {...project, isIgnored: isIgnored};
            }
            return project;
        }));
    }


    useEffect(() => {
        fetchProjects();
    }, [filters, fetchProjects]);

    const handleFilterChange = (e) => {
        let {name, value} = e.target;
        if (e.target.type === 'checkbox') {
            value = e.target.checked.toString();
        }
        setFilters(prev => ({...prev, [name]: value, page: 1}));
    };

    const handlePageChange = (newPage) => {
        setFilters(prev => ({...prev, page: newPage}));
    };

    return (
        <div className="mx-auto p-4 text-white h-screen flex flex-col max-h-screen">
            <h1 className="text-2xl font-bold mb-4">Gem Gunner Admin : Verify projects</h1>
            <FilterBar filters={filters} handleFilterChange={handleFilterChange}/>
            <div className="flex h-4/5 flex-grow">
                <VerticalDateSlider filters={filters} setFilters={setFilters}/>
                {
                    loading ? (
                        <div className="bg-black bg-opacity-50 flex items-center justify-center basis-2/3">
                            <div className="flex justify-center items-center h-64">
                                <div
                                    className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="mr-4 basis-1/3 max-w-72 flex flex-col">
                                <h2 className="text-lg font-bold mb-4">Projects:</h2>
                                <div className="grid pr-2 grid-cols-1 gap-2 overflow-y-auto custom-scrollbar">
                                    {projects.map((project) => (
                                        <ProjectCard key={project.id} project={project}/>
                                    ))}
                                    {projects.length === 0 && (
                                        <div className="text-center text-gray-400">No projects found.</div>
                                    )}
                                </div>
                                <div className="mt-4">
                                    <Pagination filters={filters} totalCount={totalCount}
                                                handlePageChange={handlePageChange}/>
                                </div>
                            </div>
                            {(editingProjectId || projects[0]?.id) &&
                                <EditProjectSection
                                    refreshProjects={fetchProjects}
                                    ignoreProject={ignoreProject}
                                    projectId={editingProjectId || projects[0]?.id}
                                />
                            }
                        </>

                    )
                }
            </div>
        </div>
    );
};

export default AdminProjectsPage;