import React from 'react';

const FilterBar = ({filters, handleFilterChange, dateFilterOptions}) => (
    <div className="mb-4 flex flex-wrap items-center gap-4">
        <div className="p-2 rounded">
            <span className="pr-2 font-bold">Sort:</span>
            <select name="sortBy" value={filters.sortBy} onChange={handleFilterChange}
                    className="bg-black rounded p-2 mr-2">
                <option value="percentScore">Percent Score</option>
                <option value="totalScore">Total Score</option>
                <option value="projectScore">Project Score</option>
                <option value="firstDeploy">First Deploy</option>
                <option value="projectName">Project Name</option>
            </select>
            <select name="order" value={filters.order} onChange={handleFilterChange}
                    className="bg-black rounded p-2">
                <option value="desc">Descending</option>
                <option value="asc">Ascending</option>
            </select>
        </div>
        <div className="p-2 gap-2 grid grid-flow-col divide-dotted divide-x items-center">
            <span className="font-bold">Filter:</span>
            <select name="valid" value={filters.valid} onChange={handleFilterChange}
                    className="bg-black px-2 border-transparent outline-0">
                <option value="">All Projects</option>
                <option value="true">Published Only</option>
                <option value="false">Draft Only</option>
            </select>
            <div className="px-2">
                <label>
                    <input type="checkbox" name="showIgnored" checked={filters.showIgnored === "true"} onChange={handleFilterChange}
                           className=""/>
                    &nbsp;Show Ignored
                </label>
            </div>
            <div className="px-2">
                <label>
                    <input type="checkbox" name="onlyPremium" checked={filters.onlyPremium === "true"} onChange={handleFilterChange}
                           className=""/>
                    &nbsp;Premium Only
                </label>
            </div>
            <select name="size" value={filters.size} onChange={handleFilterChange}
                    className="bg-black px-2 outline-0">
                <option value="10">10 per page</option>
                <option value="20">20 per page</option>
                <option value="50">50 per page</option>
            </select>
        </div>
    </div>
);

export default FilterBar;