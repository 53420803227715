import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";

const ProjectCard = ({project}) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const editingProjectId = searchParams.get('edit');
    const navigate = useNavigate();
    const handleEditClick = () => {
        navigate(`?edit=${project.id}`);
    }
    return (
        <div key={project.id}
             className="border border-purple-500 p-4 shadow-lg hover:bg-purple-950 rounded cursor-pointer flex flex-col h-full"
             style={{
                 backgroundColor: editingProjectId === project.id ? '#3b0764' : '',
                 textDecoration: project.isIgnored ? 'line-through' : ''
             }}
             onClick={handleEditClick}>
            <div className="flex items-start mb-2">
                {project.logo ? (
                    <img src={project.logo} alt={project.projectName}
                         className="w-8 h-8 mr-2 rounded-full flex-shrink-0"/>
                ) : (
                    <div
                        className="w-8 h-8 mr-2 rounded-full bg-gray-700 flex items-center justify-center flex-shrink-0">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                             strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                             className="w-4 h-4 text-gray-500">
                            <circle cx="12" cy="12" r="10"/>
                            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/>
                            <line x1="12" y1="17" x2="12.01" y2="17"/>
                        </svg>
                    </div>
                )}
                <div>
                    <h2 className="text-sm font-semibold">{project.projectName || 'Unnamed Project'}</h2>
                    <p className="text-xs text-gray-400">{project.developerName || 'Unknown Developer'}</p>
                </div>
            </div>
            <p className="text-xs text-gray-300 mb-2 flex-grow line-clamp-2">{project.description || 'No description available.'}</p>
            <div className="mt-auto">
                <div className="mb-2">
                    <p className="text-xs text-gray-400 mt-1">Score: {project.percentScore.toFixed(5)}</p>
                </div>
                <p className="text-xs text-gray-400 mb-2">Deployed: {new Date(project.firstDeploy).toLocaleDateString()}</p>
                <div className="flex justify-between">
                    {Object.entries(project.socials || {}).slice(0, 3).map(([site, social]) => (
                        <a key={site} href={social.link} target="_blank" rel="noopener noreferrer"
                           className="text-blue-400 hover:text-blue-300">
                            {site}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ProjectCard;