import React, { useState, useEffect } from 'react';
import { ArrowUpRight, Twitter, MessageCircle } from 'lucide-react';
import axiosApi from "../utils/api";


const ProjectCard = ({ project }) => {
    const [screenshotLoaded, setScreenshotLoaded] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    return (
        <div
            className="relative bg-gray-900 rounded-xl overflow-hidden group transition-all duration-300 hover:scale-[1.02]"
        >
            {/* Website Preview */}
            <div className="relative h-72 overflow-hidden bg-gray-800">
                <img
                    src={`${apiUrl}/projects/${project.id}/screenshot`}
                    alt={`${project.projectName} screenshot`}
                    className="w-full h-full object-cover transition-opacity duration-300"
                    style={{ opacity: screenshotLoaded ? 1 : 0 }}
                    onLoad={() => setScreenshotLoaded(true)}
                />
                {!screenshotLoaded && (
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-800">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
                    </div>
                )}
                <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/30 to-transparent" />
            </div>

            {/* Content */}
            <div className="p-6 relative -mt-8">
                {/* Logo and Score */}
                <div className="flex justify-between items-start mb-4">
                    <div className="flex items-center gap-3">
                        <img
                            src={project.logo}
                            alt={`${project.projectName} logo`}
                            className="w-12 h-12 rounded-xl shadow-lg border-2 border-gray-800"
                        />
                        <div>
                            <h3 className="font-bold text-xl text-white">{project.projectName}</h3>
                            <p className="text-gray-400 text-sm">{project.developerName}</p>
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        {project.isValid && (
                            <div className="bg-blue-500/10 text-blue-400 px-2 py-1 rounded-full text-xs font-medium">
                                Verified
                            </div>
                        )}
                        <div className="bg-purple-500/10 text-purple-400 px-2 py-1 rounded-full text-xs font-medium">
                            {project.percentScore.toFixed(1)}%
                        </div>
                    </div>
                </div>

                {/* Description */}
                <p className="text-gray-400 text-sm mb-4 line-clamp-3">
                    {project.description}
                </p>

                {/*/!* Stats *!/*/}
                {/*<div className="grid grid-cols-3 gap-4 mb-4">*/}
                {/*    <div className="bg-gray-800/50 rounded-lg p-3">*/}
                {/*        <p className="text-gray-400 text-xs mb-1">Deploy Date</p>*/}
                {/*        <p className="text-white font-medium">*/}
                {/*            {new Date(project.firstDeploy).toLocaleDateString()}*/}
                {/*        </p>*/}
                {/*    </div>*/}
                {/*    <div className="bg-gray-800/50 rounded-lg p-3">*/}
                {/*        <p className="text-gray-400 text-xs mb-1">Score</p>*/}
                {/*        <p className="text-white font-medium">{project.projectScore.toFixed(0)}</p>*/}
                {/*    </div>*/}
                {/*    <div className="bg-gray-800/50 rounded-lg p-3">*/}
                {/*        <p className="text-gray-400 text-xs mb-1">Contracts</p>*/}
                {/*        <p className="text-white font-medium">{project.contractAddresses.length}</p>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/* Actions */}
                <div className="flex gap-3">
                    <a
                        href={project.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex-1 bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-lg text-center transition-colors flex items-center justify-center gap-2"
                    >
                        Visit Website
                        <ArrowUpRight size={16} />
                    </a>

                    {/* Social Links */}
                    <div className="flex gap-2">
                        {project.socials.twitter && (
                            <a
                                href={project.socials.twitter.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bg-gray-800 hover:bg-gray-700 p-2 rounded-lg transition-colors"
                            >
                                <Twitter size={20} className="text-blue-400" />
                            </a>
                        )}
                        {project.socials.telegram && (
                            <a
                                href={project.socials.telegram.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bg-gray-800 hover:bg-gray-700 p-2 rounded-lg transition-colors"
                            >
                                <MessageCircle size={20} className="text-blue-400" />
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

// const TopProjectsSection = () => {
//     return (
//         <div className="bg-black py-8">
//             <div className="container mx-auto px-4">
//                 {/* Header */}
//                 <div className="flex justify-center text-center items-center mb-12">
//                     <div>
//                         <h2 className="text-4xl font-bold text-white mb-2">
//                             Top Projects This Week
//                         </h2>
//                         <p className="text-gray-400">
//                             Discover the most innovative blockchain projects
//                         </p>
//                     </div>
//                 </div>
//
//                 {/* Projects Grid */}
//                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//                     {mockProjects.map(project => (
//                         <ProjectCard key={project.id} project={project} />
//                     ))}
//                 </div>
//             </div>
//         </div>
//     );
// };

const TopProjectsSection = ({id}) => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulating API call
        setLoading(true);
        fetchProjects();
    }, []);

    const fetchProjects = () => {
        axiosApi.get("/projects?valid=true").then((response) => {
            setProjects(response.data.projects);
            setLoading(false);
        }).catch((error) => {
            console.error("Error fetching projects:", error);
        });
    };

    return (
        <section className="container mx-auto px-4 py-16" id={id}>
            <h2 className="text-3xl font-bold mb-8 text-center">Top Projects This Week</h2>
            {loading ? (
                <div className="flex justify-center items-center h-64">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
                </div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    {projects.map((project) => (
                        <ProjectCard key={project.id} project={project} />
                    ))}
                </div>
            )}
        </section>
    );
};

export default TopProjectsSection;